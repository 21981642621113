import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

export interface IComponentCanDeactivate {
    canDeactivate: () => boolean | Observable<boolean>;
}

@Injectable()
export class PendingChangesGuard {
    canDeactivate(component: IComponentCanDeactivate): boolean | Observable<boolean> {
        return component.canDeactivate() ? true : confirm('WARNING: You have unsaved changes. Press Cancel to go back and save these changes, or OK to lose these changes.');
    }
}
