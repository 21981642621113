import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { UserService } from '@core/services/user.service';
import { Observable } from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class LogoutGuard {
    constructor(
        public userService: UserService,
        public router: Router
    ) {}

    canActivate(): Observable<boolean> | Promise<boolean> | boolean {
        this.userService.logout();
        return true;
    }
}
