import { Injectable } from '@angular/core';
import { UrlTree, Router } from '@angular/router';
import { UserService } from '@core/services/user.service';
import { NavigationService } from '@core/services/navigation.service';
import { Observable } from 'rxjs';

@Injectable()
export class AuthGuard {
    constructor(
        public userService: UserService,
        public navigationService: NavigationService,
        public router: Router
    ) {}

    canActivate(): Observable<boolean> | Promise<boolean> | boolean {
        if (!this.userService.authService.isLoggedIn()) return this.handleLogout();
        return true;
    }

    canActivateChild(): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
        if (!this.userService.authService.isLoggedIn()) return this.handleLogout();
        return true;
    }

    handleLogout() {
        return this.navigationService.goToLogin();
    }
}
